<template>
  <div class="nav-bar">
    <div class="nav-bar__back" v-if="$route.query.category"><img src="@/assets/svg/arrows/back.svg" alt="" @click="$router.go(-1)"/>{{ $t('back') }}</div>
    <router-link tag="h1" to="/work-requests" class="nav-bar__title" v-if="!$route.query.category">{{ getTitle }}</router-link>
    <ui-loader v-model="loader" />
    <div v-if="!loader" class="nav-bar__links">
      <router-link
          v-for="(link, index) in getNavItems"
          :to="{ path: '/work-requests', query: { ...$route.query,  status: link.status }}"
          :key="index"
          :class="['nav-bar__links-link', { active: $route.query.status === link.status }]"
      >
        {{ $t(link.text) + count(link) }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <p class="nav-bar__text">{{$t('all')}} {{ `(${getMeta.total})` }} <img src="@/assets/svg/arrows/select-red.svg" alt="" /></p>
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in getNavItems"
              :to="{ path: '/work-requests', query: { ...$route.query,  status: link.status }}"
              :key="index"
              :class="['nav-bar__links-link', { active: $route.query.status === link.status }]"
              @click.native="visibleSettings = false"
          >
            {{ $t(link.text) + count(link) }}
          </router-link>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
import { getUserRole } from "@/utils/user";
import { capitalize } from "@/utils/general";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NavBar",

  components: {
    UiLoader: () => import('@/components/ui/UiLoader'),
    UiMenu: () => import('@/components/ui/UiMenu')
  },

  data() {
    return {
      getUserRole,
      visibleSettings: false,
      capitalize,
      loader: true,
      defaultNavItems: [
        { text: this.$t('all') },
        { text: this.$t('in-progress'), status: 'accepted' },
        { text: this.$t('closed-2'), status: 'closed' },
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.loader = false
    },500)
  },

  computed: {
    ...mapGetters(['getUser', 'getOrders', 'getMeta']),
    getNavItems () {
      return this.$route.query.type || this.$route.query.role === 'Active' ? this.defaultNavItems :
             this.$route.query.category ? [] : getUserRole(this.$route.query.role || this.getUser?.role).workRequestNavBar
    },

    getTitle () {
      console.log(this.$route, 333)
      return this.getUser.role === 'Active' && !this.$route.query.role && !this.$route.query.id ? this.$t('my-requests') : this.$t('work-requests')
    }
  },
  methods: {
    ...mapActions(['reqGetOrders']),

    count(link) {
      if(link.status === undefined) {
        return this.getMeta?.total ?  ` (${ this.getMeta?.total })` : ` (0)`
      }
      else if(link.status === 'moderation' && this.getUser.role === 'Moderator') {
        return this.getMeta?.tabs ? ` (${this.getMeta?.tabs?.ModeratorNotAssigned})` : ` (0)`
      }
      else if(link.status === 'moderation' && this.getUser.role !== 'Moderator') {
        return this.getMeta?.tabs?.Moderation ? ` (${this.getMeta?.tabs?.Moderation})` : ` (0)`
      }
      else if(this.getMeta?.tabs[capitalize(link.status)] === undefined ) {
        return ' (0)'
      }
      else {
        return this.getMeta?.tabs[capitalize(link.status)] ? ` (${this.getMeta?.tabs[capitalize(link.status)]})` : ' ' + '(' + 0 + ')'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  z-index: 48;
  background: #F5F5F5;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    margin-bottom: 50px;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__text {
    display: none;
    gap: 10px;

    @media (max-width: 768px) {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #CE2121;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 768px) {
      display: none;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      transition: 0.3s;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      &.active {
        color: #CE2121;
      }
    }
  }
}
</style>
